import React from "react";
import { useSelector } from "react-redux";
import { Card, Icon } from "semantic-ui-react";

const RenderMenuItem = ({ item }) => {
    const theme = useSelector((state) => state.common.theme);
    const { icon, color, title, flipped, secondIcon } = item;

    if (theme === "old") {
        return (
            <Card style={{ backgroundColor: "rgba(0, 0, 0, 0)", boxShadow: "none" }}>
                <Card.Content textAlign="center" style={{ padding: 5, border: 0 }}>
                    {item.secondIcon && (
                        <Icon.Group size="huge">
                            <Icon name={icon} circular color={color} inverted fitted flipped={flipped} />
                            <Icon
                                style={{
                                    color: "white",
                                    position: "absolute",
                                    top: 16,
                                    right: 21,
                                    fontSize: "2rem",
                                    transform: "rotate(-12deg)",
                                    textShadow: "none"
                                }}
                                name={secondIcon}
                                corner="top right"
                                fitted
                                flipped={flipped}
                            />
                        </Icon.Group>
                    )}
                    {!item.secondIcon && <Icon name={icon} circular color={color} inverted size="huge" flipped={flipped} />}
                </Card.Content>
                <Card.Content textAlign="center" style={{ padding: 5, border: 0 }}>
                    <Card.Header>{title}</Card.Header>
                </Card.Content>
            </Card>
        );
    }

    return (
        <div className="pwaSchaefflerCard">
            <div className="pwaSchaefflerCard__block"></div>
            <div>
                <div>
                    <div className="pwaSchaefflerCard__header-content">
                        {item.secondIcon && (
                            <Icon.Group style={{ marginBottom: "1.5rem", transform: "scale(1.2)" }} size="big">
                                <Icon
                                    name={item.secondIcon}
                                    inverted
                                    style={{
                                        color: "var(--foundational-icon-inactive)",
                                        transform: "translateY(-5px) translateX(33px) rotate(-15deg)"
                                    }}
                                />
                                <Icon name={item.icon} inverted style={{ color: "var(--foundational-icon-inactive)", transform: "scale(1.3)" }} />
                            </Icon.Group>
                        )}
                        {!item.secondIcon && (
                            <Icon
                                fitted
                                name={item.icon}
                                style={{ color: "var(--foundational-icon-inactive)" }}
                                inverted
                                size="huge"
                                flipped={item.flipped}
                            />
                        )}
                    </div>
                </div>
                <div className="pwaSchaefflerCard__footer">
                    <h4 className="pwaSchaefflerCard__footer-title">{item.title}</h4>
                </div>
            </div>
        </div>
    );
};

export default React.memo(RenderMenuItem);
