import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import { Header, Segment } from "semantic-ui-react";
import { useSelector } from "react-redux";

import i18n from "modules/i18n/i18nConfig";
import { removeAccents } from "modules/common/utils";

import TableEnhanced from "modules/common/components/TableEnhanced";
import moment from "moment";

const SynthesisPanel = (props) => {
    const {
        data,
        data: { config },
        time_periods: { comp_time }
    } = props;
    const [series, setSeries] = useState({ ref_series: [], comp_series: [] });
    const current_lng = useSelector((state) => state.i18n.current);

    useEffect(() => {
        //trigger action when data change && current_lng change
        (async () => {
            const { ref_series, comp_series } = _.chain(data.series)
                .reduce((res, series, serie_type) => {
                    res[serie_type] = _.chain(series)
                        .reduce((res, serie) => {
                            if (_.size(serie.data) > 0) {
                                res.push({
                                    ...serie,
                                    title: `${i18n._(serie.name)}`,
                                    data: _.map(serie.data, (item) => ({ ...item, title: `${i18n._(serie.name)}` }))
                                });
                            }
                            return res;
                        }, [])
                        .sortBy((item) => {
                            return removeAccents(item.title).toLowerCase();
                        })
                        .value();
                    return res;
                }, {})
                .value();
            await setSeries({ ref_series, comp_series });
        })();
    }, [data.series, current_lng]);

    const renderDataflowSvg = (serie) => {
        const color = serie?.color ?? null;
        const title = serie?.title ?? "-";
        return (
            <>
                {color && (
                    <svg height="10" width="14" style={{ marginRight: "5px" }}>
                        <rect height="10" width="14" style={{ fill: color }} />
                    </svg>
                )}
                {title}
            </>
        );
    };

    const renderLabel = (label) => {
        return `${i18n._(label)}`;
    };

    const renderLabelComp = (label) => {
        return `${i18n._(label)} (ref)`;
    };

    const mixing_series = useMemo(() => {
        const ref_wrap = _.chain(series).get("ref_series", []);
        const comp_wrap = _.chain(series).get("comp_series", []);
        // ref
        const all_total_null_ref = ref_wrap.every((serie) => serie.total === null).value();
        const ref_total_global = all_total_null_ref ? null : ref_wrap.sumBy("total").value();
        // comp
        const all_total_null_comp = comp_wrap.every((serie) => serie.total === null).value();
        const comp_total_global = all_total_null_comp ? null : comp_wrap.sumBy("total").value();
        const remap_series = ref_wrap
            .map((serie) => {
                //split min/max array in 2 variables
                const avg = serie?.avg ?? null;
                const min = serie?.min?.[1] ?? null;
                const min_day = moment(serie?.min?.[0] ?? null);
                const max = serie?.max?.[1] ?? null;
                const max_day = moment(serie?.max?.[0] ?? null);
                const ref_repartition = _.defaultTo((serie?.total / Math.abs(ref_total_global)) * 100, null);

                let remap_serie = {
                    id: { render: null, value: serie?.id, textAlign: "left", datatype: "number" },
                    title: { render: renderDataflowSvg(serie), value: serie?.title, textAlign: "left", datatype: "string" },
                    total: {
                        render: !_.isFinite(serie?.total) ? "-" : i18n.number(serie?.total, { maximumFractionDigits: 2 }),
                        value: !_.isFinite(serie?.total) ? null : serie?.total,
                        textAlign: "right",
                        datatype: "number"
                    },
                    repartition: {
                        render: !_.isFinite(ref_repartition) ? "-" : i18n.number(ref_repartition, { maximumFractionDigits: 2 }),
                        value: !_.isFinite(ref_repartition) ? null : ref_repartition,
                        textAlign: "right",
                        datatype: "number"
                    },
                    min: {
                        render: !_.isFinite(min) ? "-" : i18n.number(min, { maximumFractionDigits: 2 }),
                        value: !_.isFinite(min) ? null : min,
                        textAlign: "right",
                        datatype: "number"
                    },
                    avg: {
                        render: !_.isFinite(avg) ? "-" : i18n.number(avg, { maximumFractionDigits: 2 }),
                        value: !_.isFinite(avg) ? null : avg,
                        textAlign: "right",
                        datatype: "number"
                    },
                    max: {
                        render: !_.isFinite(max) ? "-" : i18n.number(max, { maximumFractionDigits: 2 }),
                        value: !_.isFinite(max) ? null : max,
                        textAlign: "right",
                        datatype: "number"
                    },
                    min_day: {
                        render: min_day.isValid() ? min_day.locale(current_lng).format("L") : "-",
                        value: min_day ? min_day : null,
                        textAlign: "right",
                        datatype: "date"
                    },
                    max_day: {
                        render: max_day.isValid() ? max_day.locale(current_lng).format("L") : "-",
                        value: max_day.isValid() ? max_day : null,
                        textAlign: "right",
                        datatype: "date"
                    }
                };
                //Retrieve compare serie for mixing data in same array
                const compare_serie = _.find(comp_wrap.value(), { name: serie?.name });
                if (compare_serie) {
                    const comp_repartition = _.defaultTo((compare_serie?.total / Math.abs(comp_total_global)) * 100, null);
                    remap_serie["comp_total"] = {
                        render: !_.isFinite(compare_serie?.total) ? "-" : i18n.number(compare_serie?.total, { maximumFractionDigits: 2 }),
                        value: !_.isFinite(compare_serie?.total) ? null : compare_serie?.total,
                        textAlign: "right",
                        datatype: "number"
                    };
                    remap_serie["comp_repartition"] = {
                        render: !_.isFinite(comp_repartition) ? "-" : i18n.number(comp_repartition, { maximumFractionDigits: 2 }),
                        value: !_.isFinite(comp_repartition) ? null : comp_repartition,
                        textAlign: "right",
                        datatype: "number"
                    };
                }
                return remap_serie;
            })
            .value();
        return remap_series;
    }, [series, current_lng]);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "title", label: i18n._(t`name`), textAlign: "left" },
        { id: "total", label: renderLabel("Period total consumption"), textAlign: "right" },
        { id: "repartition", label: renderLabel("global_distribution"), textAlign: "right" },
        { id: "min", label: i18n._(t`minimum`), textAlign: "right" },
        { id: "avg", label: i18n._(t`average`), textAlign: "right" },
        { id: "max", label: i18n._(t`maximum`), textAlign: "right" },
        { id: "min_day", label: i18n._(t`minimum day`), textAlign: "right" },
        { id: "max_day", label: i18n._(t`maximum day`), textAlign: "right" }
    ];

    if (comp_time !== null) {
        headCells.splice(4, 0, { id: "comp_total", label: renderLabelComp("Period total consumption"), textAlign: "right" });
        headCells.splice(5, 0, { id: "comp_repartition", label: renderLabelComp("global_distribution"), textAlign: "right" });
    }

    return (
        <>
            <Header as="h3" textAlign="center" attached>
                <Trans>synthesis table</Trans> ({config.yUnit})
            </Header>
            <Segment attached>
                <TableEnhanced headCells={headCells} rows={mixing_series} textItemPerPages={i18n._(t`items per page`)} orderBy="total" order="desc" />
            </Segment>
        </>
    );
};

export default React.memo(SynthesisPanel);
